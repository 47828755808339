import { createSlice } from '@reduxjs/toolkit';
//import { useHref } from 'react-router-dom';
const initialState = {
  value: 10,
  nama_pt: 'Datasoft Mediagraha',
  skema: 'demo',
  isi_cetakan: '',
  is_admin: false,
  sidebarUnfoldable: false,
  hasilScan: '',
  aktif_user: 0,
  id_usaha: 0,
  userku: '',
  namaku: '',
  gambarku: '',
  idku: '',
  width: 0,
  kodeserial: '',
  totalOnline: 0,
  id_sesi: null,
  token: '',
  expire: 0,
  soketObject: null,
  status: 'idle',
  appCheck: false,
  lainLain: [],
  induk1: [],
  induk2: [],
  dataakses: '',
  infoku: {
    nama: '',
    alamat: '',
    nilai: 0,
    gambarku: '',
    sidebarShow: true,
    sidebarUnfoldable: false
  
  }
};
export const reduser = createSlice({
  name: 'generalState',
  initialState,
  reducers: {    
    ubahAppCheck: (state, action) => {
      state.appCheck = true;
    },        
    isiNilai: (state, action) => {
      state.nilai= {
        nama: action.payload.nama,
        alamat: action.payload.alamat,
        nilai: state.value+action.payload.nilai,
      }
      
    },
    theRoot: (state, action) => {
      state[action.payload.nama]= action.payload.nilai;      
    },
  },
  
});

export const { isiNilai,ubahAppCheck,theRoot } = reduser.actions;
export const isiState = (state) => state.generalState.isi_cetakan;
export const selectCount = (state) => state.generalState.value;
export const lihatInfoku = (state) => state.generalState.infoku;
export const lihatAppCheck = (state) => state.generalState.appCheck;
export const lihatAkses = (state) => state.generalState.dataakses;
export const lihatWidth = (state) => state.generalState.width;
export const skema = (state) => state.generalState.skema;
export const nama_pt = (state) => state.generalState.nama_pt;
export const getToken = (state) => state.generalState.token;
export const getExpire = (state) => state.generalState.expire;
export const getIdSesi = (state) => state.generalState.id_sesi;
export const getIdku = (state) => state.generalState.idku;
export const getTotalOnLine = (state) => state.generalState.totalOnline;

export const getSideBarShow = (state) => state.generalState.sidebarShow;
export const getSideBarUnfoldable = (state) => state.generalState.sidebarUnfoldable;
export const getIsAdmin = (state) => state.generalState.is_admin;
export const getHasilScan = (state) => state.generalState.hasilScan;
export const getInfo = (state) => state.generalState.infoku;
export const getNamaku = (state) => state.generalState.namaku;
export const getGambarku = (state) => state.generalState.gambarku;
export const getKodeSerial = (state) => state.generalState.kodeserial;



export default reduser.reducer;
