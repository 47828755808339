import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
//import {withNavigate} from "./Services/WithNavigate";
//import Koneksiku from "./Services/Koneksiku";
import { connect } from "react-redux";
import {theRoot} from "./Store/reduser";
import {withNavigate} from "./Services/WithNavigate";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
//const Login = React.lazy(() => import('./views/pages/login/Login'))

const Markers= React.lazy(() => import("./maps/markers"));
const Login = React.lazy(() => import("./components2/Login2"));
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
//<Route path="/login2/:paramku" element={

let sudahProses=false
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {      
      bolehMasuk: false,      
    };

    //this.email = createRef()
    //this.password = createRef()
  }


  componentDidMount() {
    if(!sudahProses){
      sudahProses=true
      console.log("totok-1 "+new Date())
      let ka=sessionStorage.getItem('masukkelas');
      console.log("totok-2 "+new Date(),ka)
      //sessionStorage.removeItem("masukkelas");
      if(ka===null || ka === undefined){
        console.log("totok-3 "+new Date())

        //this.props.navigate('/login2/0', { replace: true })
        //return (<Login/>)
        //return false
      }else{
        this.setState({bolehMasuk: true})
      }

      let k=sessionStorage.getItem("kodeserial");
      if(k!==null){
        this.props.theRoot({nama: 'kodeserial',nilai: k})  
      }

      console.log("totok-5 "+new Date())
      k=sessionStorage.getItem("bigStore2.user");
      const k2=sessionStorage.getItem("gambarku");
      console.log('lena4 '+new Date(),k2)
      let nama=''
      let gambar=''
      if(k!==null){
          let te2 = JSON.parse(k);
          console.log('lena5 '+new Date(),te2.name)
          nama=te2.fullname
      }
      if(k2!==null){
          gambar=k2
      }

      this.props.theRoot({nama: 'infoku',nilai: {
        nama: nama,
        fullname: '',
        alamat: '',
        nilai: 0,
        gambarku: gambar,
        sidebarShow: true,
        sidebarUnfoldable: false
      }});

      
    }
  }

  render() {
    return (<>          
      {!this.state.bolehMasuk ?  <Login/>  : 
        <Suspense fallback={loading}>
          <Routes>                                
            <Route exact path="/map2" name="Maps Page" element={<Markers />} />
            <Route exact path="/login2/:paramku" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      }
      {/* </HashRouter> */}
      </>)
  }
}

/*
const mapStateToProps = (state) => ({
  generalState: state.generalState,
});
*/
export default connect((state) => ({ generalState: state.generalState }), { theRoot 
})(withNavigate(App));



//export default App
//export default (withNavigate(App));

